<template>
  <div class="container">
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>个人信息</div>
    </div>
    <div class="details_top">
      <div class="top_left">
        <img
          :src="infoData.head_img_str"
          style="
            width: 100px;
            height: 100px;
            text-align: center;
            border-radius: 50%;
          "
        />
      </div>
      <div class="top_right">
        <div class="table-info">
          <div class="info-row">
            <div class="info-list">
              <div class="info-head">{{ infoData.nick_name }}</div>
              <div class="info-details">
                <span v-if="infoData.platform_name" style="border: 1px solid #e92020;border-radius: 20%;padding:0 2px;margin-left: 5px;color: #ffffff;background: #e87878;">{{infoData.platform_name}}</span>
              </div>
            </div>
            <div class="info-list">
              <div class="info-head"></div>
              <div class="info-details"></div>
            </div>
            <div class="info-list">
              <div class="info-head">手机号：</div>
              <div class="info-details">{{ infoData.phone }}</div>
            </div>
            <div class="info-list">
              <div class="info-head">注册时间：</div>
              <div class="info-details">{{ infoData.reg_time }}</div>
            </div>
            <div class="info-list">
              <div class="info-head">最近活跃时间：</div>
              <div class="info-details">{{ infoData.last_login_time }}</div>
            </div>
            <div class="info-list">
              <div class="info-head">姓名：</div>
              <div class="info-details">{{ infoData.real_name }}</div>
            </div>
            <div class="info-list">
              <div class="info-head">生日：</div>
              <div class="info-details">{{ infoData.birthday }}</div>
            </div>
            <div class="info-list">
              <div class="info-head">性别：</div>
              <div class="info-details">
                {{
                  infoData.sex == 1 ? "男" : infoData.sex == 2 ? "nv" : "未知"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>订单列表</div>
    </div>
    <div class="main_list">
      <div class="table-box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; min-width: 1000px"
          :header-cell-style="{ background: '#F5F5F5' }"
        >
          <el-table-column label="订单编号" width="200" prop="order_sn">
          </el-table-column>
          <el-table-column label="下单时间" min-width="100">
            <template slot-scope="scope">
              <div>{{ scope.row.create_time | timer }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="订单金额（含运费）"
            prop="pay_money"
            min-width="140"
          ></el-table-column>
          <el-table-column
            label="商品金额"
            prop="goods_price"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="会员优惠"
            prop="discount_price"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="优惠券数量"
            prop="coupon_money"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="活动优惠"
            prop="activity_price"
            min-width="100"
          ></el-table-column>
          <el-table-column label="交易状态" min-width="130">
            <template slot-scope="scope">
              <div>{{ scope.row.status_str }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="100">
            <template slot-scope="scope">
              <div
                style="color: #4974f5; cursor: pointer"
                @click="toDetails(scope.row.order_id)"
                v-if="module_id_array.includes('156') || module_id_array.includes('999')"
              >
                详情
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="margin: 30px 0 40px 0"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
export default {
  filters: {
    timer(val) {
      return !val ? '' : dayjs.unix(val).format('YYYY-MM-DD HH:mm');
    },
  },
  data() {
    return {
      infoData: {}, //个人信息
      tableData: [],
      total: 0,
      page: 1,
      pageNumber: 10,
      module_id_array: window.utils.storage.getter("module_id_array") || [],
    };
  },
  created() {
    this.getdataInfo();
    this.listData();
  },
  methods: {
    getdataInfo() {
      const data = {
        user_id: this.$route.query.user_id,
      };
      this.axios.post("/store/Shopuser/getShopUserInfo", data).then((res) => {
        this.infoData = res.data;
        this.infoData.last_login_time = this.dayjs
          .unix(res.data.last_login_time)
          .format("YYYY-MM-DD HH:mm");
        this.infoData.reg_time = this.dayjs
          .unix(res.data.reg_time)
          .format("YYYY-MM-DD HH:mm");
      });
    },
    listData() {
      const data = {
        page: this.page,
        pageNumber: this.pageNumber,
        user_id: this.$route.query.user_id,
      };
      this.axios
        .post("/store/Shopuser/getShopUserOrderList", data)
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total;
        });
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.listData();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.listData();
    },
    toDetails(order_id) {
      this.$router.push({ path: "/order/orderDetail", query: { order_id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.details_top {
  display: flex;
  margin-bottom: 30px;
  .top_left {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  .table-info {
    width: 700px;
    padding: 0 11px;
    .info-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .info-list {
        width: 50%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
    }
  }
}
.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
</style>